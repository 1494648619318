
import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found｜株式会社スタートアッププラス" />
      <div id="main" className="static w-full">
        <div className=" w-full max-w-[1000px] mx-auto py-10 sm:py-10">
          <section id="PageDescription" className="w-full mb-10">
            <h1 className="my-16 text-2xl sm:text-4xl ">
              404: Not Found<br /><br />
              お探しのページは見つかりませんでした。
            </h1>
            <ul className="text-2xl">
              <li>
                <Link to="/service" className='block w-full px-4 py-2 transition hover:bg-key-sand duration-300'>
                  Service<br />
                  <span className=" text-lg ">エンジニアリング | 監視・保守 | デザイン</span>
                </Link>
              </li>
              <li className='mb-2'>
                <Link to="/product" className='block w-full px-4 py-2 transition hover:bg-key-sand duration-300'>
                  Product<br />
                  <span className=" text-lg ">モニタリングプラス | NuRseCall</span>
                </Link>
              </li>
              <li className='mb-2'>
                <Link to="/company" className='block w-full px-4 py-2 transition hover:bg-key-sand duration-300'>
                  Company<br />
                  <span className=" text-lg ">会社概要 | 沿革 | オフィス風景 | アクセスマップ</span>
                </Link>
              </li>
              <li className='mb-2'>
                <Link to="/recruit" className='block w-full px-4 py-2 transition hover:bg-key-sand duration-300'>
                  Recruit<br />
                  <span className=" text-lg ">社長からのメッセージ | 募集職種 | 働き方・福利厚生 | 採用FAQ</span>
                </Link>
              </li>
              <li className='mb-2'>
                <Link to="/aboutus-data" className='block w-full px-4 py-2 transition hover:bg-key-sand duration-300'>
                  About us<br />
                  <span className=" text-lg ">データで見るSUP | 働く環境 | メンバー紹介</span>
                </Link>
              </li>
              <li className='mb-2'>
                <Link to="/info" className='block w-full px-4 py-2 transition hover:bg-key-sand duration-300'>
                  Information<br />
                  <span className=" text-lg ">お知らせ・プレスリリースなど</span>
                </Link>
              </li>
            </ul>
          </section>
        </div>
      </div>

    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
